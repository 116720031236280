.jf-loc-landing-page {
  .section {
    border-bottom: 1px solid $grey-lighter;
    padding: 5rem 6rem;

    @include until($desktop) {
      padding-left: 0;
      padding-right: 0;

      .notification {
        padding-right: 1.5rem;
      }
    }

    &.section--fullwidth {
      padding-left: 0;
      padding-right: 0;
    }

    .housing-type-container {
      display: flex;
    }
  }

  .hero.is-light {
    padding: 1rem 5rem;
    font-size: 18px;

    @include until($desktop) {
      padding: 1rem;
    }

    h1 {
      font-size: 28px;
    }
    .jf-secondary-cta {
      font-size: 16px;
    }

    form {
      cursor: pointer;

      input {
        margin-right: 8px;
        align-self: center;
        width: 24px;
        height: 24px;
        flex-shrink: 0;
        cursor: pointer;
      }

      label {
        cursor: pointer;
      }

      /* Tooltip CSS largely taken from https://www.w3schools.com/css/css_tooltip.asp */
      .tooltip {
        position: relative;
        display: flex;
      }

      /* Tooltip text */
      .tooltip .tooltiptext {
        font-style: inherit;
        font-size: 14px;
        visibility: hidden;
        width: 400px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 8px;
        border-radius: 6px;

        /* Tooltip text position: right of info icon */
        position: absolute;
        z-index: 1;
        top: -5px;
        left: 105%;

        /* Tooltip text position: below info icon */
        @include mobile {
          width: 300px;
          top: 100%;
          left: 50%;
          margin-left: -150px; /* Use half of the width to center the tooltip */
        }

        a {
          text-decoration: underline;
        }
      }

      .tooltip:hover .tooltiptext {
        visibility: visible;
      }

      /* Tooltip arrow */
      .tooltip .tooltiptext::after {
        @include desktop {
          content: "";
          position: absolute;
          top: 15%;
          right: 100%;
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent black transparent transparent;
        }
      }
    }

    .button {
      margin-top: 1rem;
    }

    .housing-type-selection {
      display: flex;
      margin-bottom: 4px;

      @include mobile {
        align-items: flex-end;
        padding-bottom: 8px;

        label {
          margin-top: -8px;
        }

        .tooltip {
          align-self: center;
        }

        /* for radio button in mobile to be the same size as others */
        label[for="other_affordable"] {
          width: 50vw;
        }

        /* for info icon in mobile to be closer to label text*/
        .tooltip_other_affordable {
          margin-left: -50px;
        }
      }

      .jf-info-icon {
        display: flex;
        align-items: center;
        color: #0096d7 !important;
        margin-right: 0.25rem;

        @include mobile {
          margin-top: -4px;
        }
      }
    }
  }
}
