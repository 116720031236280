@charset "utf-8";
@import "./_util.scss";
@import "./_bulma-overrides.scss";
@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/bulma-divider/dist/css/bulma-divider.sass";
@import "./_supertiny.scss";
@import "./_a11y.scss";
@import "./_safe-mode.scss";
@import "./_modal.scss";
@import "./_issues.scss";
@import "./_product-icons.scss";
@import "./_landing-page.scss";
@import "./_autocomplete.scss";
@import "./_icon.scss";
@import "./_loading-overlay.scss";
@import "./_progress.scss";
@import "./_confetti.scss";
@import "./_currency-form-field.scss";
@import "./_data-driven-onboarding.scss";
@import "./_dev.scss";
@import "./_footer.scss";
@import "./_admin-conversations.scss";
@import "./_forms.scss";
@import "./_buttons.scss";
@import "./_big-list.scss";
@import "./_animations.scss";
@import "./_letter-preview.scss";
@import "./_helpers.scss";
@import "./_language-toggle.scss";
@import "./_email.scss";
@import "./_accordion.scss";
@import "./_editable-info.scss";

// These variables define the background and content colors for the nav-bar
$jf-navbar-background: $primary;
$jf-navbar-content: $primary-invert;

@import "./_navbar.scss";

@import "./_demo-deployment-note.scss";

// We want to give the page a column-based flex layout so we can
// have our safe mode UI be a sticky footer if needed.
body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

// Don't let any pre-rendered modal mess with our flex layout.
body > #prerendered-modal {
  position: absolute;
}

// If we're showing a pre-rendered modal, don't show the UI to disable safe mode.
// The user can disable it on the next/previous page outside of the modal.
body > #prerendered-modal ~ .safe-mode-disable {
  display: none;
}

.title.jf-page-steps-title {
  margin-bottom: 0.5em;
}

.jf-beta-tag:before {
  // We're assuming "beta" might be too jargony for our users, so we'll
  // use the word "new" instead.
  content: "NEW";
}

.jf-beta-tag {
  display: inline-block;
  padding: 0 4px;
  margin-left: 0.5em;
  background-color: rgba(255, 255, 255, 0.75);
  color: black;
  font-size: 0.66rem;
}

html.jf-is-fullscreen-admin-page {
  #main section {
    padding: 0;
  }

  // The following can be used to make the page have no left/right margins.
  // Right now this is overkill though and makes things hard to read on
  // really wide screens, so we're not going to use it.
  //.container {
  //    margin: 0;
  //    width: 100%;
  //    max-width: 100%;
  //}

  footer {
    display: none;
  }
}

.jf-secondary-cta {
  margin-top: 1rem;
  a {
    color: $primary !important;
    white-space: nowrap;
  }
}

.jf-moratorium-banner {
  overflow: hidden;
  .container {
    button.delete {
      &::before,
      &::after {
        background-color: $dark;
      }
    }
    p {
      margin: 0 2.5rem 0 0;
      text-align: center;
      a {
        text-decoration: underline;
        font-weight: 700;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.jf-covid-ehp-disclaimer {
  li {
    // Fix bullet spacing bug on Firefox:
    list-style-position: inside;
  }
}

.jf-sanitation-guidelines {
  .button.is-text {
    font-size: 0.9rem;
    height: 1.55rem;
    letter-spacing: 0.8px;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      text-decoration: none;
    }
  }
  @media screen and (max-width: 400px) {
    padding: 0.75rem;
  }
  .content {
    margin-top: 2.5rem;
    figure {
      margin: 0 auto;
    }
    .columns {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      p {
        line-height: 1.3;
      }
    }
  }
}

.content h2.jf-account-settings-h2 {
  font-weight: 300;
  font-size: 2rem;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 0.5rem;
}
