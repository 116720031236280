.jf-autocomplete-is-highlighted {
  color: $primary-invert;
  background-color: $primary;
}

.jf-autocomplete-is-selected {
  font-weight: bold;
}

.jf-autocomplete-field .control {
  position: relative;
}

.jf-autocomplete-field ul {
  position: absolute;
  top: 2em;
  left: 0;
  width: 100%;
  background: $primary-invert;
  border: 1px solid $grey-light;
  z-index: 100;
  border-radius: 4px;
  display: none;
}

.jf-autocomplete-field ul.jf-autocomplete-open {
  display: block;
}

.jf-autocomplete-field ul {
  /** If we're in Bulma's 'content' class we'll have a margin, so undo it. */
  margin: 0;
}

.jf-autocomplete-field ul li {
  padding: 0.5rem;
  /** If we're in Bulma's 'content' class we'll have a bullet, so undo it. */
  list-style-type: none;
}
