// This value must be mirrored in our JS by a
// similarly-named constant, JF_PROGRESS_TRANSITION_MS.
$jf-progress-transition-ms: 1000ms;

.jf-progress-title-wrapper {
  margin-bottom: 2.5rem;
}

.jf-progress-step-wrapper.jf-progress-animation-is-disabled
  > div:not(:first-child) {
  // Ugh, for some reason even if we disable enter and exit animations, there's
  // still a brief flash where both the entering and exiting step are visible
  // at once. This ensures only one of them is showing.
  display: none;
}

.jf-progress-step-wrapper {
  position: relative;

  .jf-progress-step-enter {
    position: absolute;
    transition: transform $jf-progress-transition-ms,
      opacity $jf-progress-transition-ms;
    top: 0;
    left: 0;
    transform: translate(100%);
    opacity: 0;
    width: 100%;
  }

  .jf-progress-step-enter.jf-progress-step-enter-active {
    transform: translate(0);
    opacity: 1;
  }

  .jf-progress-step-exit {
    position: relative;
    transition: transform $jf-progress-transition-ms,
      opacity $jf-progress-transition-ms;
    transform: translate(0);
    opacity: 1;
    width: 100%;
  }

  .jf-progress-step-exit.jf-progress-step-exit-active {
    opacity: 0;
  }
}

.jf-progress-forward {
  .jf-progress-step-enter {
    transform: translate(100%);
  }

  .jf-progress-step-exit-active {
    transform: translate(-100%);
  }
}

.jf-progress-backward {
  .jf-progress-step-enter {
    transform: translate(-100%);
  }

  .jf-progress-step-exit-active {
    transform: translate(100%);
  }
}
