.jf-accordion-item {
  // Have border extend beyond element boundary
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;

  details {
    p,
    summary {
      // Have border extend beyond element boundary for a better looking focus border
      padding: 0.5em 0.75rem;
      margin: 0 -0.75rem;
    }
    summary {
      // Make question box feel clickable to user
      cursor: pointer;
      // Remove default marker
      list-style-type: none;
      &::-webkit-details-marker {
        display: none;
      }
      &:focus {
        @include outline-focus();
      }
      .media .media-right {
        .image {
          margin-top: 0.15rem;
        }
        .jf-accordion-close-text-label {
          display: none;
        }
        .jf-accordion-open-text-label {
          display: block;
        }
      }
    }
    &[open] summary .media .media-right {
      .jf-accordion-close-text-label {
        display: block;
      }
      .jf-accordion-open-text-label {
        display: none;
      }
    }
  }
}
