// This value must be mirrored in our JS by a
// similarly-named constant, JF_LOADING_FADE_MS.
$jf-loading-fade-ms: 500ms;

.jf-loading-overlay-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1050;
  overflow: hidden auto;
  text-align: center;
}

.jf-loading-enter .jf-loading-overlay {
  opacity: 0;
}

.jf-loading-enter-active .jf-loading-overlay {
  opacity: 1;
}

.jf-loading-exit .jf-loading-overlay {
  opacity: 1;
}

.jf-loading-exit-active .jf-loading-overlay {
  opacity: 0;
}

.jf-loading-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $primary;
  background: $primary-invert;
  height: 100%;
  transition: opacity $jf-loading-fade-ms;

  .jf-loader {
    @include loader();
    border-bottom-color: $primary;
    border-left-color: $primary;
    width: 64px;
    height: 64px;
  }
}

.has-background-grey-lighter .jf-loading-overlay {
  background: inherit;
}
