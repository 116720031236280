// Some of the CSS for the search bar can be confusing because of how we
// want the DDO search UI to look like a splash page when there is an empty
// search query, and like a standard form at the top of the page when there
// are search results.
//
// This is further complicated by the fact that the page looks different
// when on mobile vs. desktop, and also when the address field is being
// progressively enhanced (it will actually be a text field and a set
// of radio buttons when not progressively enhanced).
//
// In short, make sure you try out the page in all possible combinations
// of the above criteria when making changes to this CSS.

// The `level` class is only applied to the search bar when the address
// field is being progressively enhanced; otherwise, there will be an
// additional set of radio buttons for the boroughs and it'll look
// weird if they're on the same horizontal level as the other form
// controls.
.level.jf-ddo-searchbar {
  .field {
    flex: 1;

    @media screen and (min-width: $tablet) {
      padding-right: 1rem;
    }
  }
}

.jf-ddo-searchbar:not(.level) {
  margin-bottom: 1.5rem;
}

.jf-ddo-searchbar {
  .button {
    margin-top: auto;
    margin-bottom: 0.75rem;
  }
}

// The search bar is in a `hero` when there is no search query (i.e.,
// when we look like a splash page rather than search results).
//
// On the splash page, when the search bar isn't progressively enhanced,
// we want the set of radio buttons for the borough to look slightly
// different from normal form controls.
.hero .jf-ddo-searchbar div[role="group"] {
  .label {
    // Don't make the "What is your borough?" label bold, it
    // sticks out too much on the splash page.
    font-weight: normal;
  }

  .control {
    // This will ensure the radio buttons are centered on the page.
    display: inline-block;
  }
}

.jf-ddo-results {
  .jf-ddo-card {
    p.subtitle,
    span.subtitle {
      display: block;
      margin-bottom: 0.25em;
      .jf-registration-warning {
        display: block;
        margin-bottom: 1rem;
      }
    }

    .button.is-primary {
      margin-top: 1.25em;
    }

    .media {
      align-items: center;

      .media-right.jf-is-hidden-supertiny {
        margin-bottom: auto;

        figure.image {
          padding: 0 0.7rem 1.4rem 0.7rem;
        }
      }
    }

    margin-bottom: 1.25em;

    @media (max-width: $jf-supertiny) {
      h3 {
        min-height: 2em;

        figure {
          margin: 0 0 0 1em;
        }
      }
    }
  }
}
