@mixin outline-focus() {
  outline-width: 1px;
  outline-style: dashed;
  outline-offset: -5px;
  outline-color: currentColor;
}

// Bulma's button default focus styling is not easy
// to distinguish, so we'll make it stand out more here.
.button:focus {
  @include outline-focus();
}

// Anything with this mixin will only be perceivable to
// screen readers.
//
// It's taken from: https://webaim.org/techniques/css/invisiblecontent/
@mixin sr-only() {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

// Bulma is supposed to support this, but at the moment it
// doesn't: https://github.com/jgthms/bulma/issues/150
//
// Until it does, we'll use our own class.
.jf-sr-only {
  @include sr-only();
}

// Sometimes we want non-interactive elements to be
// programmatically focusable; when this is the case, it's
// actually okay to not have any visual focus styling on
// the element, so we'll do that here. For more details, see:
// https://css-tricks.com/focus-styles-non-interactive-elements/
[data-jf-is-noninteractive]:focus {
  outline: none;
}
