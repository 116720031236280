.jf-currency {
  position: relative;
}

.jf-currency input {
  $padding: uncalc($control-padding-horizontal);
  padding-left: calc(#{$padding} + 1em);
  max-width: 10em;
}

.jf-currency .jf-currency-symbol {
  position: absolute;
  top: $control-padding-vertical;
  left: $control-padding-horizontal;
  content: "$";
}
