.jf-has-text-underline {
  text-decoration: underline;
}

// This is used to "glue" content together, so that e.g. an icon doesn't
// word-wrap to be orphaned from the content it's associated with.
.jf-word-glue {
  white-space: pre;
}

.jf-is-scrollable-if-too-tall {
  max-height: 50vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1em;
}

.jf-heading-with-icon {
  display: flex;

  > i > svg {
    width: 1.5em;
    height: 1.5em;
  }

  @media (min-width: $jf-supertiny + 1px) {
    > i {
      padding-right: 0.5em;
    }

    > span {
      padding-top: 0.1em;
    }
  }

  @media (max-width: $jf-supertiny) {
    flex-direction: column;

    margin-top: -0.5em;

    > i {
      text-align: center;
    }

    > span {
      padding-top: 0.5em;
    }
  }
}
