.jf-confetti-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  // This one is really important, we need to make sure the
  // content behind the confetti can be interacted with!
  pointer-events: none;

  canvas {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}
