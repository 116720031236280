.jf-letter-preview.box {
  padding: 8px;
}

.jf-letter-preview iframe[scrolling="no"] {
  display: block;
  width: 100%;
  // Note that the enclosed document inside the IFRAME needs to
  // have this max-height set; all scrolling will actually be
  // part of the enclosing document, rather than the IFRAME
  // wrapper, to work around the unusual behavior of iOS to
  // always set IFRAME dimensions to the contents of their
  // document.
  height: 300px;
}
