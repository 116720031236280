@mixin jf-navbar-site-label() {
  border-radius: 0;
  transform: rotate(-4deg);
}

html[data-safe-mode-no-js] {
  // Don't show the hamburger at all, since people
  // will just be confused by its lack of interactivity.
  .navbar-burger {
    display: none;
  }
}

@keyframes jf-slidedown {
  from {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }

  to {
    max-height: 1000px;
  }
}

html:not([data-safe-mode-no-js]) nav.navbar .is-active {
  &.navbar-menu,
  .navbar-dropdown {
    animation: jf-slidedown 0.5s;
  }
}

nav.navbar {
  background: $jf-navbar-background;

  .navbar-item:focus,
  .navbar-link:focus,
  .navbar-burger:focus {
    outline: 2px dashed $jf-navbar-content;
  }

  .navbar-item,
  .navbar-link {
    color: $jf-navbar-content;
  }

  .navbar-item figure {
    max-height: 1.5rem;
    position: relative;
    top: 1px;
  }

  .navbar-link::after {
    border-color: $jf-navbar-content;
  }

  .navbar-item.jf-navbar-label {
    padding-left: 0.5rem;

    .tag.is-warning {
      @include jf-navbar-site-label();
    }
  }

  .is-active {
    .navbar-item:focus,
    .navbar-link:focus:not([role="button"]) {
      outline: 2px dashed $jf-navbar-background;
    }

    .navbar-item,
    .navbar-link {
      color: $jf-navbar-background;
    }

    // This handles the extremely weird case where the hamburger
    // is active, but the browser window is also wide enough to
    // not be showing the menu bar.
    //
    // Most significantly, this is the case in safe mode, when
    // we show both the hamburger and all dropdowns simultaneously,
    // and have no ability to change the DOM via JS.
    //
    // This is probably a symptom of how horrible all this CSS is.
    @media screen and (min-width: $desktop) {
      .navbar-end > .navbar-item:not(:hover) {
        color: $jf-navbar-content;
        outline-color: $jf-navbar-content;
      }
    }

    .navbar-link::after {
      border-color: $jf-navbar-background;
    }
  }

  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:hover,
  .navbar-link.is-active {
    color: $jf-navbar-background;
  }

  .navbar-link:hover::after,
  .navbar-link.is-active::after {
    border-color: $jf-navbar-background;
  }

  .navbar-burger {
    color: $jf-navbar-content;
  }

  .jf-user-initials-menu {
    // We want the user's initials to fit snugly within the circle.
    // The following allows the initials "MM", which are likely the
    // most "extreme" case, to fit.
    width: 2.25em;
    height: 2.25em;

    overflow: hidden;
    border: 2px solid $jf-navbar-content;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-link:hover,
  .is-active {
    .jf-user-initials-menu {
      border-color: $jf-navbar-background !important;
    }
  }
}
