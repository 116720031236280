@import "../../node_modules/bulma/sass/utilities/functions.sass";

$justfix-blue: #0096d7;

// This is just copy-pasted from bulma's initial-variables.sass; we don't
// want to import that yet because we might want to override some of its
// variables.
$turquoise: hsl(171, 100%, 41%);

$primary: $justfix-blue;
$primary-invert: findColorInvert($primary);

$info: $turquoise;
$info-invert: findColorInvert($turquoise);

// Disable the widescreen breakpoint
$widescreen-enabled: false;

// Disable the fullhd breakpoint
$fullhd-enabled: false;
