.jf-warning-icon,
.jf-info-icon {
  margin-left: 0.25em;
  margin-right: 0.25em;

  svg {
    width: 1em;
    height: 1em;
  }
}

.jf-warning-icon {
  color: $orange;
}

a.jf-warning-icon:hover {
  color: darken($orange, 10%);
}

.jf-notice-icon {
  color: $warning;
}

a.jf-notice-icon:hover {
  color: darken($warning, 10%);
}

.jf-info-icon {
  color: $justfix-blue;
}

a.jf-info-icon:hover {
  color: darken($justfix-blue, 10%);
}
