.jf-slidedown-5em {
  animation-duration: 0.5s;
  animation-name: jf-slidedown-5em;
  overflow: hidden;
}

@keyframes jf-slidedown-5em {
  from {
    max-height: 0;
  }
  to {
    max-height: 5em;
  }
}

.jf-fadein-half-second {
  animation-duration: 0.5s;
  animation-name: jf-fadein;
}

div.jf-is-transitioning-out {
  .jf-fadein-half-second {
    animation: none;
  }
}

@keyframes jf-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.jf-slide-500px-200ms-enter {
  max-height: 0;
}

.jf-slide-500px-200ms-enter-active {
  max-height: 500px;
  transition: max-height 200ms;
}

.jf-slide-500px-200ms-exit {
  max-height: 500px;
}

.jf-slide-500px-200ms-exit-active {
  max-height: 0;
  transition: max-height 200ms;
}

.jf-up-and-down {
  animation: jf-up-and-down 3s ease-in-out infinite;
}

@keyframes jf-up-and-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0);
  }
}

// Default animation for details/summary expand icons:
details[open] summary figure {
  transition: transform 0.2s linear;
  transform: rotateX(180deg) translateY(-3px);
}

details:not([open]) summary figure {
  transition: transform 0.2s linear;
  transform: rotateX(0deg) translateY(0px);
}
