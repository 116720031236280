// Bulma's default help text size is way too small, so we'll
// make it bigger.
.help {
  font-size: inherit;
}

.jf-label-hint {
  font-weight: normal;
}

.jf-radio.radio + .jf-radio.radio {
  // This undoes Bulma's default .radio+.radio styling.
  margin-left: 0;
}

.jf-checkbox input,
.jf-single-checkbox input,
.jf-radio input {
  @include sr-only();
}

.jf-radio-symbol {
  box-shadow: 0 0 0 2px $primary-invert, 0 0 0 4px $border-hover;
  min-width: 1.2em;
  min-height: 1.2em;
  border-radius: 100%;
  margin: 0.3em 0.6em 0.3em 0.3em;
}

input:checked + .jf-radio-symbol {
  background-color: $primary;
}

input:focus + .jf-radio-symbol {
  outline: 2px dotted $border-hover;
  outline-offset: 5px;
}

input[type="number"] {
  max-width: 10em;
}

.jf-checkbox-symbol {
  min-width: 1.4em;
  min-height: 1.4em;
  margin: 0.3em 0.6em 0.3em 0;
  border-radius: 2px;
  border: 2px solid $border-hover;
  align-self: flex-start;
}

.checkbox + .jf-inset-field {
  padding-left: 1.4em + 0.3em + 0.6em;

  .label {
    font-weight: normal;
    color: $subtitle-color;
  }
}

.checkbox .jf-label-text .subtitle {
  font-size: 1rem;
}

input:checked + .jf-checkbox-symbol {
  border-color: $primary;
  background-color: $primary;

  // Note that the only color present in the background
  // image needs to be $primary-invert. If $primary ever
  // changes drastically, the background image may need
  // to be changed!
  background-image: url("./img/correct8.png");
  background-image: url("./img/correct8.svg");

  background-repeat: no-repeat;
  background-position: 50%;
}

input:focus + .jf-checkbox-symbol {
  outline: 2px dotted $border-hover;
  outline-offset: 2px;
}

.jf-radio,
.jf-checkbox,
.jf-single-checkbox {
  display: flex;
  padding: 0.25em 0;
}

.jf-radio,
.jf-checkbox {
  align-items: center;
}

.jf-single-checkbox {
  align-items: center;
}

// This class is largely empty; its main purpose is
// to wrap our label text so it breaks the flexbox layout of our
// <label> elements, which is an annoying hack. For more details,
// see https://github.com/JustFixNYC/tenants2/issues/260.
.jf-label-text {
  // This makes text wrap properly on IE11. For more details,
  // see https://stackoverflow.com/a/35113633/2422398.
  flex: 1;
}

// Until https://github.com/jgthms/bulma/issues/886 is fixed,
// Bulma doesn't have fieldset/legend styling, so we'll provide
// our own.
fieldset {
  border: none;

  legend {
    font-weight: bold;
  }

  .field:not(:last-child) {
    margin-bottom: 0;
  }
}

.jf-related-text-field-with-checkbox {
  div.field:last-child {
    margin-top: -0.75em;
  }
}

.jf-change-readonly-value {
  text-decoration: underline;
  font-size: smaller;
}

form .field {
  .control {
    input,
    select {
      &.is-danger,
      &:not(:focus).is-danger {
        border: 3px solid $danger;
      }
    }
  }

  .jf-single-checkbox.is-danger .jf-checkbox-symbol {
    border: 3px solid $danger;
  }

  p.help.is-danger {
    color: #000;
    font-size: 0.875rem;
    line-height: 0.875rem;
    margin-bottom: 0.25rem;

    &::before {
      content: "•";
      color: $danger;
      margin: 0 0.25rem 0 -0.5rem;
    }
  }
}
