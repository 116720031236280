// This variable defines how much of the page footer we want to
// appear above the fold on all pages
$footer-offset: 12px;

footer {
  background-color: #003d57;
  color: $primary-invert;

  padding: 1rem 0 0 0;

  .container {
    padding: $notification-padding;
  }

  .content {
    a,
    a:hover {
      color: inherit;
      text-decoration: underline;
    }
  }

  hr {
    height: 1px;
    opacity: 0.5;
    margin-top: 0;
  }

  ul {
    li {
      display: inline-block;
      padding-right: 1.5rem;
    }

    a {
      color: #0096d7;
      font-weight: 600;
      text-transform: uppercase;
    }

    a:hover {
      color: inherit;
    }
  }
}

.jf-above-footer-content {
  min-height: calc(100vh - #{$navbar-height} - #{$footer-offset});
}
